import Ezboximg from "../assets/images/Exboximg.svg";

const Splash = () => {
  return (
    <div className="splashdiv">
      <img src={Ezboximg} className="splashimg"></img>
    </div>
  );
};

export default Splash;
