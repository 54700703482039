import React, { useState, useEffect } from "react";
import EzbozLogo from "../assets/images/Ezlogo.svg";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Api from "../api/index";

const AppBar = () => {
  const [appRoutes, setAppRoutes] = useState([]);

  useEffect(() => {
    getPages();
  }, []);

  // func to get patient assigned therapists
  const getPages = async () => {
    let response;
    try {
      response = await Api("post", `/pages/get_all`);
      let data = response.data.data;
      data = data.sort( (a,b) => a.rank - b.rank );
      setAppRoutes(data);
    } catch (e) {
      console.trace(e);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  return (
    <>
      <nav id="navbar" class="">
        <div className="nav-wrapper">
          <div className="logoImage" style={{ paddingTop: "16px" }}>
            <Link to="/" activeClassName="active">
              <img
                className="logo"
                src={EzbozLogo}
                alt=""
              />
            </Link>
          </div>
          <ul id="menu">
            {
              appRoutes.map(
                v =>
                v.rel == "external"
                ?
                <li>
                  <a href={v.path} target="_blank">
                    {v.name}
                  </a>
                </li>
                :
                <li>
                  <Link
                    to={`/${v.path}`}
                    className={`${
                      location.pathname === `/${v.path}`
                        ? "background"
                        : ""
                    }`}
                  >
                    {v.name}
                  </Link>
                </li>
              )
            }
          </ul>
        </div>
        <div className="hamburgermenu">
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon fontSize="large"> </MenuIcon>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "100%",
                borderRadius: "10px",
                background: "#3399FE",
              },
            }}
          >
            <div className="mobilenav">
              <a href="/Advertising">Advertising</a>
              <a href="/ProductDevelopment">Product Development</a>
              <a href="/Consulting">Consulting</a>
              <a href="/ProductLine">Product Line</a>
              <a href="/Contact">Contact</a>
              <a href="#contact">Blog</a>
              <a href="#contact">Forum</a>
            </div>
          </Menu>
        </div>
      </nav>
      );
    </>
  );
};

export default AppBar;
